<app-page>
  <page-header></page-header>
  <page-content>
    <div id="page-reports-single" class="py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3">
            <img
              [src]="report.image | imageLink"
              class="img-fluid rounded-4 d-block mb-4"
              style="min-height: 400px"
              alt=""
            />
            <h6 class="fw-bold mb-0">جهة النشر</h6>
            <p class="text-muted">
              {{ report.publisher }}
            </p>
          </div>
          <div class="col">
            <h5 class="fw-bold mb-4">
              {{ report.title }}
            </h5>
            <p [innerHTML]="report.description"></p>

            <div class="text-end">
              <a
                [href]="report.reportUrl"
                target="_blank"
                class="btn btn-primary rounded-4"
                >رابط التقرير</a
              >
            </div>
          </div>
        </div>

        <h5 class="fw-bold text-center my-4">أحدث التقارير</h5>

        <div class="row">
          <div
            *ngFor="let report of reports"
            class="col-lg-3 col-md-4 col-sm-6"
          >
            <a routerLink="/reports/{{ report.id }}">
              <div class="article-card px-3 mb-4 mb-md-5">
                <img
                  class="rounded-4 d-block img-fluid"
                  style="min-height: 400px"
                  [src]="report.image | imageLink"
                  [alt]="report.image.title"
                />
                <h6 class="text-center text-dark fw-bold mt-3">
                  {{ report.title }}
                </h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </page-content>
  <page-footer></page-footer>
</app-page>
